export const labels = {
  qatar: 'Qatar',
  ecuador: 'Ecuador',
  senegal: 'Senegal',
  paisesBajos: 'Países Bajos',
  inglaterra: 'Inglaterra',
  iran: 'Irán',
  eeuu: 'EE.UU.',
  gales: 'Gales',
  argentina: 'Argentina',
  aSaudita: 'A. Saudita',
  mexico: 'México',
  polonia: 'Polonia',
  francia: 'Francia',
  australia: 'Australia',
  dinamarca: 'Dinamarca',
  tunez: 'Túnez',
  espana: 'España',
  costaRica: 'Costa Rica',
  alemania: 'Alemania',
  japon: 'Japón',
  belgica: 'Bélgica',
  canada: 'Canadá',
  marruecos: 'Marruecos',
  croacia: 'Croacia',
  brasil: 'Brasil',
  serbia: 'Serbia',
  suiza: 'Suiza',
  camerun: 'Camerún',
  portugal: 'Portugal',
  ghana: 'Ghana',
  uruguay: 'Uruguay',
  coreaDelSur: 'Corea del Sur'
};

export const groups = {
  A: ['qatar', 'ecuador', 'senegal', 'paisesBajos'],
  B: ['inglaterra', 'iran', 'eeuu', 'gales'],
  C: ['argentina', 'aSaudita', 'mexico', 'polonia'],
  D: ['francia', 'australia', 'dinamarca', 'tunez'],
  E: ['espana', 'costaRica', 'alemania', 'japon'],
  F: ['belgica', 'canada', 'marruecos', 'croacia'],
  G: ['brasil', 'serbia', 'suiza', 'camerun'],
  H: ['portugal', 'ghana', 'uruguay', 'coreaDelSur']
};
